<template>
  <v-dialog v-model="dialog" persistent max-width="650px">
    <v-card>
      <v-card-title v-text="title" />
      <v-card-text>
        <v-row>
          <v-col>
            <app-text-field v-model="form.name" label="Nome" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <OperationSelect v-model="form.operation_id" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-select
              :items="integrationTypeOptions"
              v-model="form.integration_type"
              label="Tipo de Operação"
            />
          </v-col>
          <v-col>
            <app-select
              :items="paymentMethodOptions"
              v-model="form.payment_method"
              label="Meio de Pagamento"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <app-number-field
              v-model="form.max_discount_percent"
              label="Desconto Máximo (%)"
            />
          </v-col>
          <v-col cols="6" v-if="form.payment_method == 'bill'">
            <app-number-field
              :precision="0"
              v-model="form.entry_payment_percent"
              label="Valor de Entrada (%)"
            />
          </v-col>

          <v-col v-if="form.payment_method == 'credit_card'" cols="6">
            <app-number-field
              :precision="0"
              v-model="form.max_installments"
              label="Parcelas Máximas"
            />
          </v-col>
          <v-col v-if="form.payment_method == 'bill'" cols="6">
            <app-text-field
              v-model="form.installments_interval"
              label="Intervalo de Parcelas"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              :false-value="0"
              :true-value="1"
              v-model="form.status"
              label="Cadastro Ativo"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleSave()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import OperationSelect from '../../operation/ui/OperationSelect.vue'

export default {

  components: { OperationSelect },

  data() {
    return {
      dialog: false,

      paymentMethodOptions: [
        { value: "cash", text: "Dinheiro" },
        { value: "credit_card", text: "Cartão de crédito" },
        { value: "debit_card", text: "Cartão de débito" },
        { value: "check", text: "Cheque" },
        { value: "bill", text: "Crediário" },
      ],

      integrationTypeOptions: [
        { value: "account_transaction", text: "Lançar no Caixa" },
        { value: "bill_receivable", text: "Lançar em Conta a Receber" },
      ],

      baseForm: {
        name: null,
        integration_type: "account_transaction",
        payment_method: "cash",
        entry_payment_percent: 0,
        installments_interval: 0,
        max_installments: 0,
        max_discount_percent: 0,
        status: 1,
      },
      
      form: {},
    };
  },

  computed: {
    isInsert() {
      return !this.form.id;
    },
    title() {
      return this.isInsert
        ? "Nova Forma de Pagamento"
        : "Atualizar Forma de Pagamento";
    },
  },

  mounted() {
    // this.open();
  },

  methods: {
    open(id) {
      this.reset();
      if (id) {
        this.showPaymentTerm(id);
      }
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    showPaymentTerm(id) {
      this.$loading.start();
      this.$http
        .show("settings/payment-term", id)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.payment_term));

          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },

    handleSave() {
      this.$loading.start();

      if (this.isInsert) {
        this.storePaymentTerm();
      }
      if (!this.isInsert) {
        this.updatePaymentTerm();
      }
    },

    storePaymentTerm() {
      this.$http
        .store("settings/payment-term", this.form)
        .then((response) => {
          this.processSave();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    updatePaymentTerm() {
      this.$http
        .update("settings/payment-term", this.form.id, this.form)
        .then((response) => {
          this.processSave();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSave() {
      this.$emit("store");
      this.dialog = false;
      this.$loading.finish();
    },

    // get last day of month
    getLastDayOfMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },

    // return brazil uf name by ddd





    


  },
};
</script>

<style>
</style>