<template>
  <div>
    <v-row>
      <v-col cols="4">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          class="text-capitalize"
          bottom
          large
          color="primary"
          @click="handleNewPaymentTerm()"
          >Novo</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :items-per-page="-1"
          :headers="headers"
          :items="payment_terms"
          @click:row="handleNewPaymentTerm($event.id)"
          hide-default-footer
          disable-sort
        >
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <StorePaymentTermForm @store="select()" ref="StorePaymentTermForm" />
  </div>
</template>

<script>
import StorePaymentTermForm from "@/components/payment-terms/forms/StorePaymentTermForm.vue";

export default {
  components: { StorePaymentTermForm },

  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      // { text: "integration_type", value: "integration_type" },
      // { text: "payment_method", value: "payment_method" },
      // { text: "entry_payment_percent", value: "entry_payment_percent" },
      // { text: "installments_interval", value: "installments_interval" },
      // { text: "max_installments", value: "max_installments" },
      // { text: "max_discount_percent", value: "max_discount_percent" },
      { text: "Status", value: "status", align: "center", width: "10%" },
    ],

    searchParams: {
      page: 1,
    },

    payment_terms: [],
  }),

  created() {
    this.select();
  },

  methods: {
    select(page) {
      this.searchParams.page = page;
      1;
      this.$http
        .index("settings/payment-term", this.searchParams)
        .then((response) => {
          this.payment_terms = response.payment_terms;
        });
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleNewPaymentTerm(id = null) {
      this.$refs.StorePaymentTermForm.open(id);
    },
  },
};
</script>

<style>
</style>